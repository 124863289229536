.dx-scrollview-content:has(.TwoFA-QR) {
    .TwoFA-QR {
        background-color: white;
        padding: 10px;
        svg {
            display: flex;
            margin: auto;
            align-self: center;
            @media only screen and (max-width: 500px) {
                height: 150px;
            }
        }
    }
    .description {
        margin-left: 75px !important;
    }
}
