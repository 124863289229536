@import '../../Styles.scss';
body:has(.PublicContentCrossx) {
    .dx-card {
        width: 500px !important;
    }
    .dx-scrollview-content:has(.login-form) {
        padding-top: 40px !important;
        .login-form {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .dx-form {
                width: 80%;
            }
        }

        .dx-field-button-item {
            margin-top: 15px;
            text-align: center !important;
            .dx-button {
                width: 50% !important;
            }
        }
        .title {
            visibility: hidden;
        }
        .description {
            visibility: hidden;
        }
    }

    .dx-scrollview-content:has(.dx-loadindicator) {
        .dx-card.content {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}
