@import '../../Styles.scss';
body:has(.PublicContentK33) {
    .dx-button-text {
        color: var(--backgroundColor);
    }
    .dx-card {
        width: 500px !important;
        &:has(.login-form) {
            padding-top: 40px !important;
            padding-bottom: 60px;
            min-height: unset !important;
            height: unset !important;
            .header {
                margin-bottom: unset !important;
            }
            .title {
                margin-bottom: unset !important;
            }
            .description {
                margin: unset !important;
            }
        }
    }

    .app-logo-svg {
        div {
            display: flex;
            justify-content: center;
        }
    }
    #PublicContent:has(.TwoFA) {
        .description {
            margin-left: 70px !important;
        }
    }
    .primary-button {
        .dx-button.dx-button-has-text {
            background-color: var(--BaseColor) !important;
        }
    }
    .secondary-button {
        .dx-button-content {
            span {
                color: var(--BaseColor) !important;
            }
            span:hover {
                color: var(--backgroundColor) !important;
            }
        }
        .dx-button-content:hover {
            span {
                color: var(--backgroundColor) !important;
            }
        }
    }
    .dx-scrollview-content:has(.dx-loadindicator) {
        .dx-card.content {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}
