@import '../../Styles.scss';
body:has(.PublicContentLaserDigital) {
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,55,1) 65%);
    .dx-button-text {
        color: var(--TextColor);
    }
    .dx-card {
        width: 500px !important;
        &:has(.login-form) {
            padding-top: 5%;
            padding-bottom: 5%;
            min-height: unset !important;
            height: unset !important;
            .header {
                margin-bottom: unset !important;
            }
            .title {
                margin-bottom: unset !important;
            }
            .description {
                margin: unset !important;
            }
        }
    }
    .dx-editor-outlined {
        box-shadow: none;
    }

    .app-logo-svg {
        div {
            display: flex;
            justify-content: center;
        }
    }
    .app-logo-img{
        width: 70%;
        align-self: center;
        padding-bottom: 5%;
    }
    #PublicContent:has(.TwoFA) {
        .description {
            margin-left: 70px !important;
        }
    }
    .primary-button {
        .dx-button.dx-button-has-text {
            background-color: var(--BaseColor) !important;
        }
    }
    .secondary-button {
        .dx-button-content {
            span {
                color: var(--BaseColor) !important;
            }
            span:hover {
                color: var(--backgroundColor) !important;
            }
        }
        .dx-button-content:hover {
            span {
                color: var(--backgroundColor) !important;
            }
        }
    }
    .dx-scrollview-content:has(.dx-loadindicator) {
        .dx-card.content {
            background: var(--blurryBackgroundColor);
            box-shadow: var(--blurryBoxShadow);
            backdrop-filter: var(--blurryFilter);
            -webkit-backdrop-filter: var(--blurryFilter);
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}
