.dx-scrollview-content:has(.login-form) {
    .title {
        font-size: 32px;
        margin-bottom: unset;
    }
    .dx-card {
        padding: unset;
        width: 400px;
        min-height: 600px !important;
    }

    .login-form {
        width: 60%;
        margin: auto;
    }
    .dx-field-item-label-location-top {
        display: none !important;
    }
}
