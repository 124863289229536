@import '../../Styles.scss';
body:has(.PublicContentLMOTCDemo) {
    .dx-button-text {
        color: var(--backgroundColor);
    }

    .dx-scrollview-content:has(.login-form) {
        padding-top: 40px !important;
        .login-form {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .dx-form {
                width: 80%;
            }
        }

        .dx-field-button-item {
            text-align: center !important;
            .dx-button {
                width: 70% !important;
            }
        }
    }

    .primary-button {
        .dx-button.dx-button-has-text {
            background-color: var(--BaseColor) !important;
        }
    }
    .secondary-button {
        .dx-button-content {
            span {
                color: var(--BaseColor) !important;
            }
            span:hover {
                color: var(--backgroundColor) !important;
            }
        }
        .dx-button-content:hover {
            span {
                color: var(--backgroundColor) !important;
            }
        }
    }
    .dx-scrollview-content:has(.dx-loadindicator) {
        .dx-card.content {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}
