body:has(.change-password-form) {
    .change-password-form{
        min-width: 100%;
    }
    .dx-field-button-item {
        text-align: center !important;
        .dx-button {
            width: 70% !important;
        }
    }
}